@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap');

:root {
    --secondary-color: #f99500;
    --background-color: #ddbd8e;
    --light-color: #f3f3f3;
    --panel-color : #fff;
    --panel-color-dark : #fdaf41;
    --panel-color-highlighted:#ffedd3;
    --border-color: rgb(34, 29, 29);
    --spinner-color: #3185f3;
    --text-color: #150c08;
    --error-color: #d61212;
    --success-color: #1d8d1d;
    --flat-button-color: #a33c0d;
    --flat-button-hover: #e15413;
    --footer-color: #333;
    --dark-color: #333;
    --link-color:#DF1C44;
    --link-color-hover: #db4160;
    --nav-color: #4b2019;
    --hero-text-color: #2e1511;
    --hero-text-color-secondary: #312f2f;
    --btn-primary:#836611;
    --btn-hover: #9d7f24;
    --link-on-purple: #eabd34;
    --link-on-purple-hover: #ffb158;
    --bg-color: #e9d4ff;
    --bg-color-secondary: #dc8b19;
    --max-width: 1100px;
    --text-color-light: #e8e8e8;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* general styling  */

html,body {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  background: var(--background-color);
}

h2, h3, h4 {
  margin: 10px 0;
}

p, label {
   color: var(--text-color);
}

p.inline-text {
  display: inline;
}

svg {
  margin: 10px;
}

textarea {
  resize: none;
  padding: 4px;
  display: block;
  width: 100%;
}

input {
  padding: 4px;
}

input[type=checkbox] {
  margin-right: 10px;
}

select {
  padding: 4px;
}

.center-text {
  text-align: center;
}

.navbar {
  display: flex;
  flex-direction: row;
  background-color: var(--nav-color);
  height: 6vh;
  padding: 10px 1rem;
  justify-content: space-between;
  align-items: center;
}

.contained-image {
  width: 100%;
}

.list-container {
  background: var(--light-color);
  border-radius: 10px;
  padding: 10px;
}

.list-grid.list-head p{
  font-weight: bold;
}

a, a:visited, a:active {
  color: var(--link-color);
  text-decoration: none;
}

a:hover {
  color: var(--link-color-hover);
  text-decoration: none;
}

.grid-col-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.panel {
  border-radius: 10px;
  background: var(--panel-color);
  padding: 10px;
}

/* spacing */
.spacer-10 {
  height: 10px;
}

/* navbar  */

.navbar * {
  z-index: 1000;
}

.navbar ul{
  display: flex;
  flex-direction: row;
  list-style-type: none;
  margin-top: 8px;
}

.navbar-logo {
  color: var(--light-color);
  font-family: 'Alfa Slab One', cursive;
}

.navbar a {
  color: var(--light-color);
  text-decoration: none;
  padding: 0 10px;
}


/* hero */
header .hero {
  background: url('./assets/img/hero.jpg') no-repeat center center/cover;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 94vh;
}

header .hero h1 {
  text-align: right;
  margin-right: 1rem;
  font-family: 'Alfa Slab One', cursive;
  font-size: 4.5rem;
  letter-spacing: 3px;
  color: var(--hero-text-color);
}


header .hero h2 {
  text-align: right;
  margin-right: 4rem;
  font-size: 2.5rem;
  color: var(--hero-text-color-secondary);
}

/* card styling */

.card {
  display: flex;
  flex-direction: column;
  background-color: var(--panel-color);
  border-radius: 10px;
  margin: 10px auto;
  width: 100%;
}

.card .card-picture img{
  width: 100%;
}

.card .card-title {
  padding: 10px;
}

.card .card-title p {
  font-size: 1.5rem;
  font-family: 'Alfa Slab One', cursive;
  color: var(--hero-text-color);
  text-align: center;
}

.card .card-body {
  padding: 10px;
}
/* section styles general  */

.section-heading {
  text-align: center;
  font-family: 'Alfa Slab One', cursive;
  font-weight: 400;
  font-size: 2.5rem;
  color: var(--hero-text-color);
  margin-bottom: 2rem;
  /* border-bottom: 2px solid var(--hero-text-color); */
}

.section-heading span {
  position: relative;
  padding-bottom: 5px;
}
.section-heading span::after{
  position: absolute; 
  left: 0; 
  bottom: 0; 
  width: 100%; 
  height: 1px; 
  border-bottom: 1px solid #000; 
  content: ""
}

section {
  padding: 0 2rem;
}

section p {
  margin-bottom: 10px;
}

/* one page sections  */

section.one-page {
  min-height: 88vh;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

section.scroll-page {
  min-height: 88vh;
}

.message-content * {
  margin: 1rem 0;
}

/* buttons */
.flat-btn-sm {
  display: block;
  background: none;
  border: none;
  font-family: 'Alfa Slab One', cursive;
  font-size: .65rem;
  color: var(--flat-button-color);
  margin: auto;
  padding: 3px;
  border: 1px solid var(--flat-button-color);
  border-radius: 10px;
  transition: all;
  transition-duration: 1s;
}

.flat-btn-sm:hover {
  cursor: pointer;
  color: var(--flat-button-hover);
  border: 1px solid var(--flat-button-hover);
}

.flat-btn-sm.pills-btn {
  display: inline;
}

.pills-selected {
  color: var(--flat-button-hover);
  border: 1px solid var(--flat-button-hover);
}

/* LISTS  */

.list-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 4px;
}

.list-item {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.address-block {
  margin-bottom: 10px;
}

.address-block p {
  margin: 0;
}

/* USER LISTS */
.attendee-list-item-profile,
.attendee-list-item {
  display: grid;
  grid-template-columns: 2fr 2fr 3fr 1fr 1fr;
  background: var(--panel-color);
  padding: 10px;
  border-radius: 10px;
  margin: 10px 0;
}

.match-item {
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 1fr;
  background: var(--panel-color);
  padding: 10px;
  border-radius: 10px;
  margin: 10px 0;
}

.attendee-list-item-profile .img-container,
.attendee-list-item .img-container,
.match-item .img-container {
  width: 100px;
  height: 100px;
}

.attendee-list-item-profile img,
.attendee-list-item img,
.match-item img  {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

/* forms  */
.form-group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: 10px 0;
}

.form-container {
  width: 40%;
  margin: auto;
}

.form-submit {
  margin: 10px 0;
}

.form-errors ul {
  padding-left: 30px;
}

.form-errors li{
  color: var(--error-color);
}

form input[type=submit],
.flat-button {
  display: block;
  background: none;
  border: none;
  font-family: 'Alfa Slab One', cursive;
  font-size: 1rem;
  color: var(--flat-button-color);
  margin: auto;
  padding: 4px;
  border: 1px solid var(--flat-button-color);
  border-radius: 10px;
  transition: all;
  transition-duration: 1s;
}

form input[type=submit]:hover,
.flat-button:hover {
  cursor: pointer;
  color: var(--flat-button-hover);
  border: 1px solid var(--flat-button-hover);
}

.btn-group {
  display: inline-flex !important;
  margin: 10px 0;
  flex-direction: row !important;
}

.btn-group *{
  margin-right: 10px;
}

.btn-group.contained-buttons {
  margin: 0 10px;
}

.btn-group.contained-buttons button {
  flex: auto;
  margin: 0 10px;
}

/* profile form  */

#profile-form .form-group {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  align-items: stretch;
}

#profile-form textarea {
  width: 98%;
}

#profile-form .form-group * {
  margin: 4px;
}

#profile-form .profile-form-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}

#profile-form .profile-image-display {
  width: 100%;
  height: 80%;
}

.profile-form-container .form-col {
  display: flex;
  flex-direction: column;
}

#profile-picture {
  display: none;
}

.profile-image-display {
  display: block;
  padding: 10px;
}

.portrait-image {
  max-width: 80%;
  max-height: 100%;
  margin: auto!important;
  display: block;
}


/* profile  */

.profile-grid {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 10px;
}

.profile-grid div{
  display: flex;
  flex-direction: column;
}


.profile-grid * {
  margin-bottom: 10px;
}

.profile-grid img{
  width: 80%;
}

/* footer */

footer {
  text-align: center;
  background: var(--footer-color);
  min-height: 6vh;
}

footer p {
  color: var(--light-color);
  padding: 20px;
}

/* EVENTS  */
.noplaces-container {
  text-align: center;
}

.noplaces-container *{
  margin: 10px;
}


.noplaces-text {
  display: inline-block;
  background: none;
  border: none;
  font-family: 'Alfa Slab One', cursive;
  font-size: 1rem;
  color: var(--flat-button-color);
  margin: auto;
  padding: 4px;
  border: 1px solid var(--flat-button-color);
  border-radius: 10px;
}

.map-link {
  display: flex;
  align-items: center;
}

.map-link svg {
  color: var(--link-color);
}

/* MESSAGES  */

.messages-container {
  /* display: grid;
  grid-template-columns: 1fr 2fr; */
  display: flex;
  flex-direction: row;
}

.messages-list-container {
  width: 40%;
}

.messages-list-item {
  display: grid;
  grid-template-columns: 1fr 3fr 2fr;
  background: var(--panel-color);
  border: var(--border-color) solid 1px;
  border-radius: 6px;
  margin: 2px 0;
  padding: 10px;
  cursor: pointer;
}

.messages-list-item p {
  font-size: .75rem;
  padding: 0 2px;
}

.selected-message-item {
  background: var(--panel-color-highlighted);
}

.message-panel, 
.no-message-container {
  margin-left: 10px;
  background: var(--panel-color);
  border-radius: 10px;
  padding: 10px;
  width: 60%;
}

.no-message-container {
  text-align: center;
}

.messages-list-item.message-list-head {
  background: var(--panel-color-dark);
}


/* MODAL  */

.modal-container {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}

.modal {
  position: relative;
  top: 6rem;
  width: 60%;
  padding: 1rem;
  text-align: center;
  background: #fff;
  border-radius: 10px;
  margin: auto;
  z-index: 100;
}

.modal .list-item {
  display: grid;
  grid-template-columns: 1fr 2fr;
  justify-items: start;
  margin: 10px 0;
}

.modal .list-item input{
  width: 100%;
}

.modal .list-item select{
  width: 100%;
}

.modal-overlay {
  overflow: hidden;
}

/* modal overlay */
.modal-overlay::after{
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0,.4);
}

.disable-scroll {
  overflow: hidden;
}

.modal-scroll {
  max-height: 50vh;
  overflow-x: hidden;
  overflow-y: scroll;
}

.modal-scroll ul {
  text-align: left;
  list-style-type: disc;
  margin-left: 30px;
}

.modal-scroll p, ul {
  margin-bottom: 10px;
}

/* MESSAGE DISPLAY */
.error-message-container p{
  text-align: center;
  color: var(--error-color);
}

.success-message-container p{
  text-align: center;
  color: var(--success-color);
}

 /* LOADING WIDGET  */

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2rem;
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid var(--spinner-color);
  border-color: var(--spinner-color) transparent var(--spinner-color) transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

/* MEDIA QUERIES  */

@media(max-width: 800px) {
  #events .grid-col-3 {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
  }

  .form-container {
    width: 100%;
  }

  html,body {
    font-size: 16px;
  }
}


@media(max-width: 700px) {
  html,body {
    font-size: 14px;
  }
}

@media(max-width: 600px) {
  header .hero {
    background: url('./assets/img/hero_mob.jpg') no-repeat center center/cover;
  }

  .hero h1 {
    text-align: center !important;
    font-size: 2.5rem !important;
    margin: 0 auto !important;
  }

  .hero h2 {
    text-align: center !important;
    font-size: 1.5rem !important;
    margin: 0 auto !important;
  }

  /* hide the underline  */
  .section-heading span::after{
    position: absolute; 
    left: 0; 
    bottom: 0; 
    width: 0; 
    height: 0; 
    content: "";
  }

  .messages-container {
    flex-direction: column;
    height: 80vh;
  }

  .messages-list-container {
    width: 100%;
    height: 50%;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .message-panel {
    width: 100%;
    margin: 10px 0;
  }
}



@media(max-width: 450px) {
  html,body {
    font-size: 12px;
  }

  .hide-mob {
    display: none;
  }

  .list-grid {
    grid-template-columns: 1fr 1fr;
  }

  .navbar {
    flex-direction: column;
    height: 10vh;
  }

  .profile-grid, .profile-form-container, .list-item {
    grid-template-columns: 1fr !important;
  }

  .attendee-list-item-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .match-item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .profile-grid .card {
    padding-bottom: 10px;
  }
}


@media(max-width: 290px) {
  .navbar ul {
    flex-direction: column;
  }

  .navbar {
    height: auto;
  }

  .hero h1 { 
    font-size: 1.5rem !important;
  }

  .hero h2 { 
    font-size: 1rem !important;
  }

  section {
    padding: 0 1rem;
  }
}